import React from 'react';
import Spinner from "components/Spinner"
import loadable from '@loadable/component';

const LoadableCleanRoom = loadable(() => import('components/CleanRoomCertificationPage'), {
  fallback: (
    <Spinner />
  ),
});
const CleanRoomPage = () => {
  return <LoadableCleanRoom />;
};
export default CleanRoomPage;
